import { hasProperty } from '@/helpers/typeHelpers';
import hljs from 'highlight.js';

export const highlight = {
  mounted(el: HTMLElement, binding: unknown) {
    if (el && hasProperty<{ value: string }>(binding, 'value')) {
      el.textContent = binding.value;
      hljs.highlightElement(el);
    }
  },
  updated(el: HTMLElement, binding: unknown) {
    if (!el || !hasProperty<{ value: string }>(binding, 'value')) {
      return;
    }

    if (el.getAttribute('scrolling') === 'true') {
      el.setAttribute('scrolling', 'false');
    } else {
      el.removeAttribute('data-highlighted');
      el.textContent = binding.value;
      hljs.highlightElement(el);
    }
  },
};
