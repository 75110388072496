import './assets/css/base.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import FloatingVue from 'floating-vue';

import VueDatePicker from '@vuepic/vue-datepicker';

//@ts-ignore
import { outsideClick } from '@altium/identity-ui-library';
import CKEditor from '@mayasabha/ckeditor4-vue3';

import '@vuepic/vue-datepicker/dist/main.css';
import '../node_modules/@altium/identity-ui-library/dist/style.css';
import 'vue-toast-notification/dist/theme-default.css';

import App from './App.vue';
import router from './router';
import { showErrorEx } from './helpers/notificationsHelper';
import { highlight } from './directives/highlight';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(FloatingVue);
//@ts-ignore
app.use(CKEditor);

app.component('VueDatePicker', VueDatePicker);
app.directive('outside-click', outsideClick);
app.directive('highlight', highlight);

app.mount('#app');

app.config.errorHandler = (err) => {
  showErrorEx(err, 'An unexpected error occured');
  console.error(err);
};
