import { ApiException } from '@/api/api';
import type { ApiErrorResult } from '@/api/apiHelper';
import { useToast } from 'vue-toast-notification';

const toast = useToast({
  position: 'top',
  duration: 5000,
  dismissible: true,
});

export function showNotification(msg: string, type: 'info' | 'error' | 'warning' | 'success') {
  toast.open({
    message: msg,
    type: type,
  });
}

export function showError(msg: string) {
  showNotification(msg, 'error');
}

export function showErrorEx(ex: unknown, fallbackMsg: string) {
  if (ex instanceof ApiException) {
    const err = ex.result as ApiErrorResult;
    showNotification(err.ErrorMessage ?? fallbackMsg, 'error');
    return;
  }

  showNotification(fallbackMsg, 'error');
}
