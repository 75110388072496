<template>
  <div class="l-page">
    <div class="l-page__inner">
      <div class="l-container">
        <div class="l-page__main">
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>
